import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';

import css from './MarketplaceSubHero.module.css';

const MarketPlaceSubHero = props => {
  return (
    <div className={css.container}>
      <h4 className={css.heading}>As seen on</h4>
      <div className={css.imagesContainer}>
        <div className={css.msn} />
        <div className={css.yahoo} />
        <div className={css.microsoft} />
        <div className={css.national} />
        <div className={css.insider} />
      </div>
    </div>
  );
};

MarketPlaceSubHero.defaultProps = {};

MarketPlaceSubHero.propTypes = {};

export default MarketPlaceSubHero;
