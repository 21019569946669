import React from 'react';

import css from './MarketplaceHero.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';

const MarketPlaceHero = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.backdropImage}>
        <div className={css.hero}>
          <div className={css.heroSections}>
            <div className={css.heroContent}>
              <div className={css.heroSubSection}>
                <img
                  src="static/images/Hero_Header.svg"
                  alt="Hero Header"
                  className={css.heroHeader}
                />
                <div className={css.heroInfo}>
                  <p className={css.text}>
                    GAMEVERTISED - The marketplace where game studios, iGaming brands and streamers
                    team up and get things done. Start planning your iGaming campaign{' '}
                    <b>FOR FREE TODAY!</b>
                  </p>
                  <div className={css.buttons}>
                    <button className={css.primaryButton} onClick={handleCtaClick}>
                      Browse all streamers
                    </button>
                    <NamedLink name={'SignupPage'} className={css.ctaLink}>
                      Signup as a streamer
                    </NamedLink>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.heroContent}>
              <img src="static/images/Hero_Image.svg" alt="Hero Image" className={css.heroImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MarketPlaceHero.defaultProps = {};

MarketPlaceHero.propTypes = {};

export default MarketPlaceHero;
