import React from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';

import { CiCircleCheck } from 'react-icons/ci';
import css from './MarketPlaceCta.module.css';
import { useHistory } from 'react-router-dom';

const CtaContent = props => {
  return (
    <div className={css.ctaSubContent}>
      <h4 className={css.ctaSubHeading}>
        <CiCircleCheck /> {props.title}
      </h4>
      <p className={css.ctaInfo}>{props.description}</p>
    </div>
  );
};

CtaContent.defaultProps = {
  title: null,
  description: null,
};

CtaContent.propTypes = {
  title: string,
  description: string,
};

const MarketPlaceCta = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.centered}>
        <div className={css.sections}>
          <div className={css.ctaContent}>
            <h2 className={css.ctaHeading}>Game on, Your Way!</h2>

            <CtaContent
              title={'Instant Connections'}
              description={'Meet top brands and game studios without the hassle.'}
            />

            <CtaContent
              title={'Secure Payments'}
              description={'Get paid through our secure escrow system. No more payment worries.'}
            />

            <CtaContent
              title={'Boost your Reach'}
              description={'Get discovered by brands looking for talents like you.'}
            />

            <CtaContent
              title={'Easy Scheduling'}
              description={'Manage your gigs with our simple and intuitive calendar.'}
            />

            <div className={css.buttonContainer}>
              <button onClick={handleCtaClick} className={css.primaryButton}>
                Browse all streamers
              </button>
            </div>
          </div>
          <div className={css.ctaVideoContainer}>
            <iframe
              className={css.ctaVideo}
              src="https://www.youtube.com/embed/hZQXp_AJenw?si=Qx2SGIkUwLqBFylz"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </div>
        </div>
      </div>
    </div>
  );
};

MarketPlaceCta.defaultProps = {};

MarketPlaceCta.propTypes = {};

export default MarketPlaceCta;
